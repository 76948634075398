var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"reservation-modification"},[(!_setup.loading)?_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[_c('div',{staticClass:"reservation-modification-header"},[_c('v-sheet',[_c('h1',{class:[
            'mb-4 pa-2',
            {
              'text-h4': !_setup.isMobile,
              'text-h6 font-weight-bold': _setup.isMobile
            }
          ]},[_vm._v("\n          "+_vm._s(_setup.t('Reservation#Modify#Heading Manage your reservation'))+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"my-5 d-flex justify-center"},[_c('v-sheet',{attrs:{"elevation":"1","width":_setup.infoCardWidth}},[_c('v-simple-table',{staticClass:"pt-2 pb-0",attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('td',[_c('nobr',[_c('fa-icon',{attrs:{"icon":['fat', 'ticket']}}),_vm._v(" "),_c('span',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v("\n                      "+_vm._s(_setup.t('Reservation#Modify#Step Reservation'))+"\n                    ")])],1)],1),_vm._v(" "),_c('td',{staticClass:"text-subtitle-1"},[_c('nobr',[_vm._v(_vm._s(_vm.reservationId))])],1)]),_vm._v(" "),_c('tr',[_c('td',[_c('nobr',[_c('fa-icon',{attrs:{"icon":['fat', 'hotel']}}),_vm._v(" "),_c('span',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v("\n                      "+_vm._s(_setup.t('App#Hotel Hotel'))+"\n                    ")])],1)],1),_vm._v(" "),_c('td',{staticClass:"text-subtitle-1"},[_vm._v("\n                  "+_vm._s(_setup.store.getters.hotelName)+"\n                ")])]),_vm._v(" "),_c('tr',[_c('td',[_c('nobr',[_c('fa-icon',{attrs:{"icon":['fat', 'calendar']}}),_vm._v(" "),_c('span',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v("\n                      "+_vm._s(_setup.isMobile
                          ? _setup.t('Reservation#Field Arrival')
                          : _setup.t('Search#Form#Field Check In'))+"\n                    ")])],1)],1),_vm._v(" "),_c('td',{staticClass:"text-subtitle-1"},[_c('nobr',[_vm._v(_vm._s(_setup.checkIn))])],1)]),_vm._v(" "),_c('tr',[_c('td',[_c('nobr',[_c('fa-icon',{attrs:{"icon":['fat', 'calendar']}}),_vm._v(" "),_c('span',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v("\n                      "+_vm._s(_setup.isMobile
                          ? _setup.t('Reservation#Field Departure')
                          : _setup.t('Search#Form#Field Check Out'))+"\n                    ")])],1)],1),_vm._v(" "),_c('td',{staticClass:"text-subtitle-1",staticStyle:{"height":"35px"}},[_c('nobr',[_vm._v(_vm._s(_setup.checkOut))])],1)])])])],1)],1)],1)]):_vm._e(),_vm._v(" "),(_setup.loading)?_c(_setup.Loader):_c('transition',{staticClass:"d-flex align-start",attrs:{"slot":"reservation-content","tag":"div","name":"fade","mode":"out-in","appear":""},slot:"reservation-content"},[_c('v-stepper',{model:{value:(_setup.state.activeStep),callback:function ($$v) {_vm.$set(_setup.state, "activeStep", $$v)},expression:"state.activeStep"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"step":"1","complete":_setup.state.activeStep > 1},on:{"click":function($event){_setup.state.activeStep = 1}}},[_vm._v("\n          "+_vm._s(_setup.t('Reservation#Modify#Step Reservation'))+"\n        ")]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-stepper-step',{attrs:{"step":"2","complete":_setup.state.activeStep > 2},on:{"click":function($event){_setup.state.activeStep = 2}}},[_vm._v("\n          "+_vm._s(_setup.t('Reservation#Modify#Step Profile'))+"\n        ")]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-stepper-step',{attrs:{"step":"3"},on:{"click":function($event){_setup.state.activeStep = 3}}},[_vm._v("\n          "+_vm._s(_setup.t('Reservation#Modify#Step Payment'))+"\n        ")])],1),_vm._v(" "),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"mt-1",attrs:{"lg":"6","md":"9","sm":"12"}},[_c('div',{staticClass:"reservation-modification__rooms"},[_c('p',{staticClass:"text-body-2"},[_vm._v("\n                  "+_vm._s(_setup.t(
                      'Reservation#Modify#Step#Reservation#Caption Please verify the details of your reservation below and contact us if anything is not correct.'
                    ))+"\n                ")]),_vm._v(" "),(_setup.state.isProductCreated)?_c(_setup.ReservationRooms,{attrs:{"is-reservation-expired":_setup.state.isReservationExpired,"room-stays":_setup.roomStays,"show-toggler":false}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"total grand-total"},[_c(_setup.OrderTotalPrice)],1)],1)])],1),_vm._v(" "),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"lg":"3","sm":"6"}},[_c('v-btn',{staticClass:"text-none mb-3",attrs:{"color":"primary","disabled":false,"ripple":false,"block":"","tile":"","x-large":""},on:{"click":function($event){_setup.state.activeStep += 1}}},[_vm._v("\n                "+_vm._s(_setup.t('Form#Button#Next Next'))+"\n              ")])],1)],1)],1),_vm._v(" "),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"mt-1",attrs:{"lg":"6","md":"9","sm":"12"}},[_c('p',{staticClass:"text-body-2 mb-8"},[_vm._v("\n                "+_vm._s(_setup.t(
                    'Reservation#Modify#Step#Profile#Caption Please verify and complete your profile information below to speed-up your check-in at the hotel.'
                  ))+"\n              ")]),_vm._v(" "),_c(_setup.ReservationForm,{attrs:{"role":_setup.ReservationRole.Modify,"show-title":false,"guest-info":_setup.store.getters.bookingRequest.guestInfo,"is-reservation-expired":_setup.state.isReservationExpired},on:{"validity-state":function($event){_setup.state.isProfileFormValid = $event},"cancel-reservation":_setup.cancelReservation}},[_c('div',{staticClass:"modification-deadline-text mt-3 px-4 text-caption text-center text-disabled font-weight-bold",attrs:{"slot":"modification-deadline"},slot:"modification-deadline"},[_vm._v("\n                  "+_vm._s(_setup.modificationDeadlineText)+"\n                ")])]),_vm._v(" "),_c('div',{staticClass:"d-flex justify-space-between mt-4"},[_c('v-btn',{class:[
                    'text-none',
                    {
                      'mr-10': !_setup.isMobile,
                      'mb-7': _setup.isMobile
                    }
                  ],attrs:{"color":"primary","width":"33%","disabled":false,"ripple":false,"outlined":"","tile":"","x-large":""},on:{"click":function($event){_setup.state.activeStep--}}},[_vm._v("\n                  "+_vm._s(_setup.t('Cancellation#Form#Popup#Button#Cancel Cancel'))+"\n                ")]),_vm._v(" "),_c('v-btn',{class:['text-none mb-3', { 'ml-10': !_setup.isMobile }],attrs:{"color":"primary","width":"33%","disabled":!_setup.state.isReservationExpired && !_setup.state.isProfileFormValid,"ripple":false,"tile":"","x-large":""},on:{"click":_setup.modifyProfile}},[_vm._v("\n                  "+_vm._s(_setup.t('Form#Button#Next Next'))+"\n                ")])],1)],1)],1)],1),_vm._v(" "),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"lg":"6","md":"9","sm":"12"}},[_c('div',{staticClass:"py-10 text-center"},[_vm._v("\n                "+_vm._s(_setup.t(
                    'Checkout#Payment#Unavailable No payment options are available at the moment.'
                  ))+"\n              ")])])],1),_vm._v(" "),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"lg":"4","sm":"6"}},[_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary","disabled":false,"ripple":false,"block":"","outlined":"","tile":"","x-large":""},on:{"click":function($event){_setup.state.activeStep--}}},[_vm._v("\n                "+_vm._s(_setup.t('Cancellation#Form#Popup#Button#Cancel Cancel'))+"\n              ")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }